<template>
    <AppPanel subtitle="Listagem de importaçao de cadastro de estrutura">
        <template #content>
            <AppPaginatedGrid
                ref="grid"
                :service="service"
                newPath="/importacao-cadastro-estrutura/new/tipo-importacao"
                editPath="/importacao-cadastro-estrutura/"
                :expander="false"
                subtitle="Importação Cadastro Estrutura"
                @onToggleMenu="onToggleMenu"
                :actionItems="actionItems"
                tooltip="Cadastro Importação Estrutura"
                permission="importacao_cadastro_estrutura"
                nomeTelaDoManual="importacao-cadastro-estrutura-list"
            >
                <template #columns>
                    <Column field="cliente.name" header="Cliente" :sortable="true" sortField="cliente.name">
                        <template #body="slotProps">
                            {{ slotProps.data?.cliente?.name }}
                        </template>
                    </Column>
                    <Column field="tipoImportacao" header="Tipo Importação" :sortable="true" sortField="tipoImportacao">
                        <template #body="slotProps">
                            {{
                                slotProps.data.tipoImportacao === 'NC'
                                    ? 'Novo contrato'
                                    : slotProps.data.tipoImportacao === 'AC'
                                    ? 'Alteração Cadastral'
                                    : ''
                            }}
                        </template>
                    </Column>
                    <Column field="etapaProcesso" header="Etapa Processo" :sortable="true" sortField="etapaProcesso">
                        <template #body="slotProps">
                            {{ slotProps.data.etapaProcesso === 'D' ? 'Download' : slotProps.data.etapaProcesso === 'C' ? 'Concluído' : '' }}
                        </template>
                    </Column>
                    <Column field="createdAt" header="Data de Importação" :sortable="true" sortField="createdAt">
                        <template #body="slotProps">
                            {{ $filters.formatDateOnly(slotProps.data.createdAt) }}
                        </template>
                    </Column>
                    <Column field="userCreate.name" header="Usuário" :sortable="true" sortField="userCreate.name">
                        <template #body="slotProps">
                            {{ slotProps.data?.userCreate?.name }}
                        </template>
                    </Column>
                </template>
            </AppPaginatedGrid>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';

export default {
    data() {
        return {
            service: null,
            record: {},
            actionItems: [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    visible: () => this.record.etapaProcesso === 'D',
                    command: () => this.editRecord(this.record)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.$refs.grid.confirmDeleteRecord()
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/importacao-estrutura');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        async editRecord(record) {
            this.$router.push(`/importacao-cadastro-estrutura/${record.id}/tipo-importacao`);
        },
        onToggleMenu(event, data) {
            this.record = data;
        }
    }
};
</script>

